import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCollection, getDocument, createDocument, deleteDocument, snapshot, storageRef, fileURL, fsBatch, docRef, api } from "../config";
import { chunk } from "../../services/utilities";
import moment from "moment";

const DEFAULT_FILTERS = {
  brands:[],
  external_ids:[],
  locations:[],
  statuses:[],
  types:[]
};

const DEFAULT_ALGOLIA_FILTERS = [
  'NOT tags:"referral"'
];

export const bulkUpdate = createAsyncThunk(
  "event_reservations/bulkUpdate",
  async (payload, { getState, rejectWithValue }) => {
    try{
      const { account, settingsAccount, event_reservations } = getState();

      if(payload.type==='ach') payload.selected = event_reservations.selectedReservations.filter(r=>r.attention_to.length).map(r=>r.id);

      switch(payload.type) {
        case 'approve':
          await api.eventReservationBulkApprove({
            reservationIDs:payload.selected,
            updateBy:account.user.parentUserID?account.user.parentUserID:account.user.uid
          });
        break;
        case 'payment':
          await api.eventReservationBulkPayments({
            reservationIDs:payload.selected,
            skipPaymentNotify:payload.sendEmail===false,
            updateBy:account.user.parentUserID?account.user.parentUserID:account.user.uid
          });
        break;
        case 'ach':
          let chunks = chunk(500,payload.selected);
          let batch = null;
          let data = {
              achFrom:{...settingsAccount.selectedAccount}
          };
          for (var i = 0; i < chunks.length; i++) {
              batch = fsBatch();
              for (var j = 0; j < chunks[i].length; j++) {
                batch.update(docRef('event_reservation_payments',chunks[i][j]),{...data});
              }
              await batch.commit();
          }
        break;
      }
     
      return payload;
    }catch(e){
      return rejectWithValue(e.message);
    }
  }
);

export const csvDownload = createAsyncThunk(
  "event_reservations/csvDownload",
  async (d, { rejectWithValue }) => {
    try{
      if(d.exists&&d.data()&&d.data().destination) {        
        let sRef = await storageRef(d.data().destination);
        let url = await fileURL(sRef);
        let response = await fetch(url);
        let buffer = await response.arrayBuffer();
        url = window.URL.createObjectURL(new Blob([buffer],{type:'text/csv'}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', sRef.name);
        document.body.appendChild(link);
        link.click();
        return true;
      }else if(d.exists&&d.data()&&d.data().errorMessage){
        return rejectWithValue(`ERROR: ${d.data().errorMessage}`);
      }
      return false;
    }catch(e){
      return rejectWithValue(e.message);
    }
  }
);

export const csvExport = createAsyncThunk(
  "event_reservations/csvExport",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try{
      const {algolia, account, event_reservations} = getState();

      const rs = await api.exportEventReservations({
        columns:event_reservations.columns,
        search:event_reservations.searchTerm,
        filters:event_reservations.algoliaFilters,
        userkey:algolia.key,
        indexName:event_reservations.index.indexName,
        externalIDName:event_reservations.externalIDName
      });

      const {data} = rs;

      return snapshot('user_exports',data.id,(d)=>dispatch(csvDownload(d)),{includeMetadataChanges:true});
    }catch(e){
      return rejectWithValue(e.message);
    }
  }
);

export const removeColumnset = createAsyncThunk(
  "event_reservations/removeColumnset",
  async (_, { getState, rejectWithValue }) => {
    try{
      const {account,event_reservations} = getState();
      await deleteDocument(`user_columns/${account.user.uid}/sets`,event_reservations.columnSet.id);
      return getCollection(`user_columns/${account.user.uid}/sets`);
    }catch(e){
      return rejectWithValue(e.message);
    }
  }
);

export const saveColumnset = createAsyncThunk(
  "event_reservations/saveColumnset",
  async (payload, { getState, rejectWithValue }) => {
    try{
      const {account,event_reservations} = getState();
      let param = {
          entity:`user_columns/${account.user.uid}/sets`,
          merge:true
      };
      if (event_reservations.columnSet) param.id = event_reservations.columnSet.id;
      await createDocument(param,payload);
      return getCollection(`user_columns/${account.user.uid}/sets`);
    }catch(e){
      return rejectWithValue(e.message);
    }
  }
);

export const getUserPublic = createAsyncThunk(
  "event_reservations/getUserPublic",
  async (_, { getState, rejectWithValue }) => {
    try{
      const {account} = getState();
      return getDocument(`users_public`, account.user.parentUserID||account.user.uid);
    }catch(e){
      return rejectWithValue(e.message);
    }
  }
);

export const getUserColumnSets = createAsyncThunk(
  "event_reservations/getUserColumnSets",
  async (_, { getState, rejectWithValue }) => {
    try{
      const {account} = getState();
      return getCollection(`user_columns/${account.user.uid}/sets`);
    }catch(e){
      return rejectWithValue(e.message);
    }
  }
);

export const getReservationsFromAlgolia = createAsyncThunk(
  "event_reservations/getReservationsFromAlgolia",
  async (_, { getState, rejectWithValue }) => {
    const { event_reservations, pager } = getState();
    try{
      const response = await event_reservations.index.search(event_reservations.searchTerm, {
        maxValuesPerFacet: 2000,
        filters: event_reservations.algoliaFilters.join(' AND '),
        hitsPerPage: event_reservations.limit,
        page: pager.page,
        facets: ['brand_name', 'external_id', 'location_name', 'status', 'event_name'],
        sortFacetValuesBy: "alpha",
      });
      return response;
    }catch(e){
      return rejectWithValue(e.message);
    }
  }
);

export const eventsSlice = createSlice({
  name: "events",
  initialState: {
    loading:true,
    filtering:false,
    exporting:false,
    bulking:false,
    error:null,
    errorColumns:null,
    savingColumns:false,
    userPublic:{},
    activeModal:null,
    events:[],
    all: false,
    order: 'event_day',
    orderBy: 'asc',
    dateRange: {start:moment().startOf('year'),end:moment().endOf('year')},
    selectedEventType:'upcoming',
    lastEventType:'upcoming',
    activeModal:null,
    showFilters:false,
    index:null,
    filterBrands:[],
    selectedReservations:[],
    selectedBrands:[],
    selectedExternalIDs:[],
    selectedLocations:[],
    selectedStatuses:[],
    selectedTypes:[],
    filterExternalIDs:[],
    filterLocations:[],
    filterStatuses:[],
    filterTypes:[],
    externalIDName:'External ID',
    includeExternal:false,
    searchTerm:'',
    algoliaFilters:[...DEFAULT_ALGOLIA_FILTERS],
    filters:DEFAULT_FILTERS,
    limit:20,
    pages:0,
    userColumnSets:[],
    columnSet:null,
    columns:['event_day','event_name','brand_name','location_name','status','sales','donation','payment_sent','fundraiser_org_name','org_type','fundraiser_name','attention_to','mailing_address','attendees'],
    tempColumns:[],
    fields: [
      {
          key: 'id',
          label: 'ID',
          sortable: false
      },
      {
          key: 'created',
          label: 'Created',
          sortable: true
      },
      {
          key: 'event_day',
          label: 'Event Date',
          sortable: true
      },
      {
         key: 'event_name',
         label: 'Event',
         sortable: false
      },
     {
        key: 'brand_name',
        label: 'Brand',
        sortable: false
     },
     {
         key: 'external_id',
         label: 'External ID',
         sortable: false
     },
     {
         key: 'location_name',
         label: 'Location Name',
         sortable: true
     },
     {
         key: 'location_address',
         label: 'Location Address',
         sortable: false
     },
     {
         key: 'location_city',
         label: 'Location City',
         sortable: false
     },
     {
         key: 'location_state',
         label: 'Location State',
         sortable: false
     },
     {
         key: 'location_zip',
         label: 'Location Zip',
         sortable: false
     },
     {
         key: 'status',
         label: 'Status',
         sortable: false,
         class: 'text-center'
     },
     {
         key: 'sales',
         label: 'Total Sales',
         sortable: true,
         sortDirection:'desc',
         class:'text-end'
     },
     {
         key: 'instore_sales',
         label: 'In-Store Sales',
         sortDirection:'desc',
         class:'text-end'
     },
     {
         key: 'online_sales',
         label: 'Online Sales',
         sortDirection:'desc',
         class:'text-end'
     },
     {
         key: 'transactions',
         label: 'Total Transactions',
         sortDirection:'desc',
         class:'text-end'
     },
     {
         key: 'instore_transactions',
         label: 'In-Store Transactions',
         sortDirection:'desc',
         class:'text-end'
     },
     {
         key: 'online_transactions',
         label: 'Online Transactions',
         sortDirection:'desc',
         class:'text-end'
     },
     {
         key: 'donation',
         label: 'Donation Amount',
         sortable: true,
         sortDirection:'desc',
         class:'text-end'
     },
     {
         key: 'payment_sent',
         label: 'Payment Sent',
         sortable: false,
         class:'text-center',
     },
     {
         key: 'payment_sent_date',
         label: 'Payment Sent Date',
         sortable: false,
     },
     {
         key: 'approved_date',
         label: 'Approved Date',
         sortable: false,
     },
     {
         key: 'fundraiser_org_name',
         label: 'Fundraiser',
         sortable: true
     },
     {
            key: 'org_type',
            label: 'Org. Type',
            sortable: false
      },
      {
          key: 'org_size',
          label: 'Org. Size',
          sortable: false
      },
     {
         key: 'fundraiser_name',
         label: 'Fundraiser Contact',
         sortable: false
     },
     {
         key: 'fundraiser_org_address',
         label: 'Fundraiser Address',
         sortable: false
     },
     {
         key: 'fundraiser_org_city',
         label: 'Fundraiser City',
         sortable: false
     },
     {
         key: 'fundraiser_org_state',
         label: 'Fundraiser State',
         sortable: false
     },
     {
         key: 'fundraiser_org_zip',
         label: 'Fundraiser Zip',
         sortable: false
     },
     {
         key: 'fundraiser_org_taxid',
         label: 'Fundraiser TaxID',
         sortable: false
     },
     {
         key: 'fundraiser_taxid_verified',
         label: 'TaxID Verified',
         sortable: false,
         class: 'text-center'
     },
     {
         key: 'fundraiser_org_taxdoc',
         label: 'Tax Document',
         sortable: false,
         class: 'text-center'
     },
     {
         key: 'fundraiser_optin_marketing',
         label: 'Opt in Marketing',
         sortable: false,
         class: 'text-center'
     },
     {
         key: 'fundraiser_email',
         label: 'Fundraiser Email',
         sortable: false
     },
     {
         key: 'fundraiser_phone',
         label: 'Fundraiser Phone',
         sortable: false
     },
     {
         key: 'payment_type',
         label: 'Payment Type',
         sortable: false
     },
     {
         key: 'payable_to',
         label: 'Payable To',
         sortable: false
     },
     {
         key: 'attention_to',
         label: 'Attention To',
         sortable: false
     },
     {
         key: 'mailing_address',
         label: 'Mailing Address',
         sortable: false
     },
     {
         key: 'mailing_city',
         label: 'Mailing City',
         sortable: false
     },
     {
         key: 'mailing_state',
         label: 'Mailing State',
         sortable: false
     },
     {
         key: 'mailing_zip',
         label: 'Mailing Zip',
         sortable: false
     },
     {
         key: 'attendees',
         label: 'Est. Attendees',
         sortable: false
     }
   ],
   completed:[],
   approved:[],
   pendingCheck: [],
   listener:null,
   achFrom:null
  },
  reducers: {
    setAll: (state, action) => {
      state.all = action.payload;
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setColumns: (state, action) => {
      state.columns = action.payload;
    },
    setColumnSet: (state, action) => {
      state.columnSet = action.payload;
    },
    setActiveModal: (state,action) => {
      if(action.payload==='columns') state.errorColumns = null;
      state.activeModal = action.payload;
    },
    setSelectedEventType: (state,action) => {
      state.selectedEventType = action.payload;
    },
    setSelectedReservations: (state,action) => {
      state.selectedReservations = state.events.map(r=>action.payload.includes(r.id)?r:null).filter(r=>r);
    },
    setLastEventType: (state,action) => {
      state.lastEventType = action.payload;
    },
    setSearchTerm: (state,action) => {
      state.searchTerm = action.payload;
    },
    removeFilterItem: (state,action) => {
      state.filters[action.payload.type].splice(action.payload.index,1);
    },
    clearAllFilters: (state) => {
      state.filters = DEFAULT_FILTERS;
    },
    applyFilters: (state, action) => {
      
      let algoliaFilters = [...DEFAULT_ALGOLIA_FILTERS];
      let filters = state.filters;

      state.filtering = true;
      
      let dateRangeStart = null;
      let dateRangeEnd = null;
      
      switch(state.selectedEventType){
        case 'date-range':
          dateRangeStart = state.dateRange.start;
          dateRangeEnd = state.dateRange.end;
          algoliaFilters.push('event_day > ' + moment(dateRangeStart).tz(moment.tz.guess()).startOf('day').unix().toString());
          algoliaFilters.push('event_day < ' + moment(dateRangeEnd).tz(moment.tz.guess()).endOf('day').unix().toString());
        break;
        case 'upcoming':
          algoliaFilters.push('event_day_end > ' + moment().unix());
        break;
        case 'past':
          algoliaFilters.push('event_day_end < ' + moment().unix());
        break;
      }

      if(state.showFilters){
        filters.brands = filters.brands.concat(state.selectedBrands.map(b=>b));
        filters.brands = filters.brands.filter((a,b)=>filters.brands.indexOf(a) == b);
        filters.external_ids = filters.external_ids.concat(state.selectedExternalIDs.map(l=>l));
        filters.external_ids = filters.external_ids.filter((a,b)=>filters.external_ids.indexOf(a) == b);
        filters.locations = filters.locations.concat(state.selectedLocations.map(l=>l));
        filters.locations = filters.locations.filter((a,b)=>filters.locations.indexOf(a) == b);
        filters.statuses = filters.statuses.concat(state.selectedStatuses.map(s=>s));
        filters.statuses = filters.statuses.filter((a,b)=>filters.statuses.indexOf(a) == b);
        filters.types = filters.types.concat(state.selectedTypes.map(s=>s));
        filters.types = filters.types.filter((a,b)=>filters.types.indexOf(a) == b);
      }

      if(filters.brands.length>0){
        algoliaFilters.push('('+filters.brands.map(b=>'brand_name:"'+b+'"').join(' OR ')+')');
      }

      if(filters.locations.length>0){
        algoliaFilters.push('('+filters.locations.map(l=>'location_name:"'+l+'"').join(' OR ')+')');
      }

      if(filters.external_ids.length>0){
        algoliaFilters.push('('+filters.external_ids.map(l=>'external_id:"'+l+'"').join(' OR ')+')');
      }

      if(filters.statuses.length>0){
        algoliaFilters.push('('+filters.statuses.map(l=>'status:"'+l+'"').join(' OR ')+')');
      }

      if(filters.types.length>0){
        algoliaFilters.push('('+filters.types.map(l=>'event_name:"'+l+'"').join(' OR ')+')');
      }

      state.selectedBrands = [];
      state.selectedExternalIDs = [];
      state.selectedLocations = [];
      state.selectedStatuses = [];
      state.selectedTypes = [];

      state.filters = filters;
      state.algoliaFilters = algoliaFilters;

    },
    onFilterChange: (state,action) => {
      if(action.payload.pageQuery&&action.payload.field==='selectedStatuses'&&action.payload.value[0].value==='Approved') state['selectedStatuses']=['Approved','Confirmed'];
      else state[action.payload.field]=action.payload.value.map(v=>v.value);
    },
    clearSearch: (state) => {

    },
    setEventReservationsIndex: (state, action) => {
      state.index = action.payload;
    },
    setShowFilters: (state, action) => {
      state.showFilters = action.payload;
    },
    setTempColumns: (state, action) => {
      state.tempColumns = action.payload;
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload;
    },
    setAchFrom: (state, action) => {
      state.achFrom = action.payload;
    }
  },
  extraReducers: {
    [bulkUpdate.fulfilled]: (state, action) => {
      if(action.payload.type=='approve') state.approved = action.payload.selected;
      else if(action.payload.type=='ach') state.pendingCheck = action.payload.selected;
      else state.completed = action.payload.selected;
      state.bulking = false;
      return;
    },
    [bulkUpdate.pending]: (state, action) => {
      state.error = null;
      state.bulking = true;
      return;
    },
    [bulkUpdate.rejected]: (state, action) => {
      state.error = action.payload;
      state.bulking = false;
      return;
    },
    [csvDownload.fulfilled]: (state, action) => {
      if(action.payload) {
        state.exporting = false;
      }
      return;
    },
    [csvDownload.rejected]: (state, action) => {
      state.listener();
      state.error = action.payload;
      state.exporting = false;
      return;
    },
    [csvExport.fulfilled]: (state, action) => {
      state.listener = action.payload;
      return;
    },
    [csvExport.pending]: (state, action) => {
      state.listener = null;
      state.error = null;
      state.exporting = true;
      return;
    },
    [csvExport.rejected]: (state, action) => {
      state.listener = null;
      state.error = action.payload;
      state.exporting = false;
      return;
    },
    [removeColumnset.fulfilled]: (state, action) => {
      state.userColumnSets = action.payload;
      state.savingColumns = false;
      state.activeModal =  null;
      return;
    },
    [removeColumnset.pending]: (state, action) => {
      state.errorColumns = null;
      state.savingColumns = true;
      return;
    },
    [removeColumnset.rejected]: (state, action) => {
      state.errorColumns = action.payload;
      state.savingColumns = false;
      return;
    },
    [saveColumnset.fulfilled]: (state, action) => {
      state.userColumnSets = action.payload;
      state.savingColumns = false;
      state.activeModal =  null;
      return;
    },
    [saveColumnset.pending]: (state, action) => {
      state.errorColumns = null;
      state.savingColumns = true;
      return;
    },
    [saveColumnset.rejected]: (state, action) => {
      state.errorColumns = action.payload;
      state.savingColumns = false;
      return;
    },
    [getUserPublic.fulfilled]: (state, action) => {
      state.userPublic = action.payload?action.payload:{};
      return;
    },
    [getUserPublic.pending]: (state, action) => {
      state.userPublic = {};
      return;
    },
    [getUserPublic.rejected]: (state, action) => {
      state.error = action.payload;
      state.userPublic = {};
      return;
    },
    [getUserColumnSets.fulfilled]: (state, action) => {
      state.userColumnSets = action.payload;
      return;
    },
    [getUserColumnSets.pending]: (state, action) => {
      state.userColumnSets = [];
      return;
    },
    [getUserColumnSets.rejected]: (state, action) => {
      state.error = action.payload;
      state.userColumnSets = [];
      return;
    },
    [getReservationsFromAlgolia.fulfilled]: (state, action) => {
      state.pages=action.payload.nbPages;
      if(action.payload.facets.brand_name) state.filterBrands = Object.keys(action.payload.facets.brand_name).map(b=>Object.assign({},{value:b,label:b}));
      if(action.payload.facets.location_name) state.filterLocations = Object.keys(action.payload.facets.location_name).map(l=>Object.assign({},{value:l,label:l}));
      if(action.payload.facets.external_id) state.filterExternalIDs = Object.keys(action.payload.facets.external_id).map(nbr=>Object.assign({},{value:nbr,label:nbr}));
      if(action.payload.facets.status) state.filterStatuses = Object.keys(action.payload.facets.status).map(s=>Object.assign({},{value:s,label:s}));
      if(action.payload.facets.event_name) state.filterTypes = Object.keys(action.payload.facets.event_name).map(s=>Object.assign({},{value:s,label:s}));
      state.events = action.payload.hits.map(e=>{return {id:e.objectID,...e};});
      state.loading = false;
      state.filtering = false;
      state.showFilters = false;
      return;
    },
    [getReservationsFromAlgolia.pending]: (state, action) => {
      state.loading = true;
      return;
    },
    [getReservationsFromAlgolia.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      return;
    }
  }
});

export default eventsSlice.reducer;
export const {
  setAll,
  setLimit,
  setColumnSet,
  applyFilters,
  setSearchTerm,
  setActiveModal,
  setShowFilters,
  clearSearch,
  setEventReservationsIndex,
  setSelectedReservations,
  onFilterChange,
  removeFilterItem,
  clearAllFilters,
  setSelectedEventType,
  setLastEventType,
  setTempColumns,
  setColumns,
  setDateRange,
  setOrder,
  setOrderBy,
  setAchFrom
} = eventsSlice.actions;
